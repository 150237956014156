/* global stripCurrency */
import { getDateParts } from 'ReactComponents/Display/helpersBetweenAngularAndReact';

(function () {
  'use strict';

  angular
    .module('blocks.display')
    .service('displayService', displayService);

  /* @ngInject */
  function displayService(
    $filter,
    $window,
    i18nService,
  ) {

    var service;

    service = {
      accessArea,
      accessPermit,
      accessPermitWithDetails,
      address,
      adminDevice,
      approverFullName,
      competence,
      competenceModel,
      contract,
      contractA,
      contractPromisorDetails,
      contractPromiseeDetails,
      contractHtmlBlocks,
      contractHtmlBlocksWithTimePeriod,
      contractHtmlInline,
      contractHtmlInlineWithTimePeriod,
      contractTimePeriod,
      country,
      currency,
      day,
      device,
      deviceWithLocationAndDirection,
      jobNumber,
      jobNumberHtmlBlock,
      jobTitle,
      manpowerArea,
      material,
      month,
      monthShort,
      nationality: country,
      niceDate,
      fullDate,
      payment,
      person,
      personReversed,
      personReversedHtmlBlock,
      profession,
      range,
      site,
      siteWithAddress,
      region,
      uniqueContract,
      user,
      userInfo,
    };

    return service;

    ////

    function defaultEscape(str) {
      return str;
    }

    ////

    function accessPermit(myAccessPermit, escape = defaultEscape, args) {
      let str = '';
      if (_.get(args, 'block')) {
        str += '<div>';
      }
      str += escape(myAccessPermit.site.number) + ' ' + escape(myAccessPermit.site.name);
      if (myAccessPermit.contract) {
        str += ' / ' + escape(myAccessPermit.contract.b.name);
      }
      if (_.get(args, 'block')) {
        str += '</div>';
      }
      return str;
    }

    function accessPermitWithDetails(myAccessPermit, escape = defaultEscape, args) {
      let str = '';
      if (_.get(args, 'block')) {
        str += '<div>';
      }
      str += escape(myAccessPermit.site.number) + ' ' + escape(myAccessPermit.site.name);
      if (myAccessPermit.contract) {
        str += ' / ' + escape(myAccessPermit.contract.contractNameWithNumber);
        if (myAccessPermit.contract.underASideContract) {
          str += ' (' + escape(myAccessPermit.site.developer.name);
          if (myAccessPermit.contract.a &&
              myAccessPermit.site.developer.name !== myAccessPermit.contract.a.name) {
            str += ' / ' + escape(myAccessPermit.contract.a.name);
          }
          str += ')';
        } else {
          str += ' (' + escape(myAccessPermit.site.mainContractor.name);
          if (myAccessPermit.contract.a &&
              myAccessPermit.site.mainContractor.name !== myAccessPermit.contract.a.name) {
            str += ' / ' + escape(myAccessPermit.contract.a.name);
          }
          str += ')';
        }
      }
      var startDate = myAccessPermit.validationPeriod.startDate;
      var endDate = myAccessPermit.validationPeriod.endDate;
      str += ' ' + escape(startDate) + '-' + escape(endDate);

      if (_.get(args, 'block')) {
        str += '</div>';
      }

      return str;
    }


    function address(addressObj, inputParams = {}) {

      if (!addressObj) {
        return '';
      }

      const params = {
        postcode: true,
        ...inputParams,
      };

      const myAddress = addressObj.address || addressObj;
      const postcode = params.postcode ? (myAddress.postcode || '') : '';
      const street = myAddress.street || '';
      const city = myAddress.city || '';
      const postcodeAndCity = postcode + (postcode && city ? ' ' : '') + city;
      return street + (street && postcodeAndCity ? ', ' : '') + postcodeAndCity;

    }

    function adminDevice(myAdminDevice) {
      var str = myAdminDevice.model;
      str += myAdminDevice.location ? ' // ' + myAdminDevice.location : '';
      str += myAdminDevice.imei ? ' // ' + myAdminDevice.imei + ' ' + myAdminDevice.telephone : '';
      str += myAdminDevice.ownerName ? ' // ' + myAdminDevice.ownerName : '';
      return str;
    }

    function approverFullName(item, escape) {
      return '<div>' +
        '<span>' +
          '<span class="">' + escape(item.lastName) + '</span>, ' +
          '<span class="">' + escape(item.firstName) + '</span>' +
        '</span>' +
      '</div>';
    }

    function competence(myCompetence) {
      var str = myCompetence.model.name;
      if (myCompetence.expirationDate && myCompetence.model.expirationType === 'BY_MONTH') {
        if (myCompetence.expirationDate.indexOf('/') === -1) {
          str += ' (' + moment(myCompetence.expirationDate, 'D.M.YYYY').format('MM/YYYY') + ')';
        } else {
          str += ' (' + moment(myCompetence.expirationDate, 'MM/YYYY').format('MM/YYYY') + ')';
        }
      } else if (myCompetence.expirationDate && myCompetence.model.expirationType === 'BY_DAY') {
        str += ' (' + moment(myCompetence.expirationDate, 'D.M.YYYY').format('D.M.YYYY') + ')';
      } else if (myCompetence.issueDate) {
        str += ' (' + myCompetence.issueDate + ')';
      }
      return str;
    }

    function competenceModel (myCompetenceModel) {
      var str = myCompetenceModel.name;
      return str;
    }

    function _contract (myContract, escape = defaultEscape, args) {

      if (!myContract) {
        return;
      }
      var str = '';
      var defaultClass = args.block ? ' block ' : '';
      if (args.html) {
        str += '<div><span class="' + defaultClass + '">';
      }
      str += contractPromisorName(myContract, escape);
      if (args.html) {
        str += '</span>';
        str += '<span class="small ' + defaultClass + '">';
      }
      if (myContract.a?.id !== myContract.b?.id) {
        str += ' ' + contractPromiseeDetails(myContract, escape);
      }
      if (args.includeTimePeriod) {
        str += _contractTimePeriod(myContract, escape, args);
      }
      if (args.html) {
        str += '</span></div>';
      }
      return str;
    }

    function contract(myContract, escape) { // selectize uses escape
      var str = _contract(myContract, escape, { html: false, block: false });
      return str;
    }

    function contractHtmlInline (myContract, escape) {
      var str = _contract(myContract, escape, { html: true, block: false });
      return str;
    }

    function contractHtmlInlineWithTimePeriod (myContract, escape) {
      var str = _contract(myContract, escape, { html: true, block: false, includeTimePeriod: true });
      return str;
    }

    function contractHtmlBlocks (myContract, escape) {
      var str = _contract(myContract, escape, { html: true, block: true });
      return str;
    }

    function contractHtmlBlocksWithTimePeriod (myContract, escape) {
      var str = _contract(myContract, escape, { html: true, block: true, includeTimePeriod: true });
      return str;
    }

    function contractPromiseeDetails (myContract, escape = defaultEscape) {

      if (!myContract.a) {
        return '';
      }
      var str = '(';
      if (myContract.number) {
        str += escape(myContract.number) + ' / ';
      }
      str += escape(myContract.a.name);
      if (myContract.rootLevelContractorName &&
          myContract.rootLevelContractorName !== myContract.a.name) {
        str += ' :: ' + myContract.rootLevelContractorName;
      }
      str += ')';
      return str;
    }

    function contractPromisorName(myContract, escape = defaultEscape) {
      var str = escape(myContract.b ? myContract.b.name : '');
      if (myContract.companyUnit) {
        str += ' / ' + escape(myContract.companyUnit);
      }
      return str;
    }

    function contractPromisorDetails(myContract, escape = defaultEscape) {
      var str = contractPromisorName(myContract, escape);
      if (myContract.number) {
        str += ' - ' + escape(myContract.number);
      }
      return str;
    }

    function contractTimePeriod(myContract, escape, args) {
      return _contractTimePeriod(myContract, escape, { includeTimePeriod: true, block: args?.block });
    }

    function _contractTimePeriod(myContract, escape, args) {
      var str = '';
      if (args.includeTimePeriod && !args.block) {
        str += ' | ' + myContract.timePeriod.startDate + ' - ' + myContract.timePeriod.endDate;
      } else if (args.includeTimePeriod && args.block) {
        str += '<span class="block">';
        str += myContract.timePeriod.startDate + ' - ' + myContract.timePeriod.endDate;
        str += '</span>';
      }
      return str;
    }

    function uniqueContract(myContract) {
      if (!myContract) {
        return;
      }
      var str = myContract.b.name;
      if (myContract.companyUnit) {
        str += ' / ' + myContract.companyUnit;
      }
      if (myContract.number) {
        str += ' (' + myContract.number + ')';
      } else if (myContract.contractor && myContract.contractor.id !== myContract.b.id) {
        str += ' (' + myContract.contractor.name + ')';
      }
      return str;
    }

    function contractA(myContract) {

      if (!myContract) {
        return '';
      }

      var str = myContract.a.name;
      if (myContract.companyUnitForB) {
        str += ' / ' + myContract.companyUnitForB;
      }
      if (myContract.number) {
        str += ' (' + myContract.number + ')';
      }
      return str;

    }

    function currency (amount) {
      if (amount) {
        var newAmount = stripCurrency(amount);
        return $filter('currency')(newAmount);
      } else {
        return amount;
      }
    }

    function day(dateParam) {
      var dateParts = getDateParts(dateParam);
      return moment([dateParts.year, dateParts.month - 1, dateParts.day]).format('dddd');
    }

    function device(myDevice) {
      var str = myDevice.model.name;
      str += myDevice.imei ? ' (' + myDevice.imei + ')' : '';
      return str;
    }

    function deviceWithLocationAndDirection(myDevice) {
      var str = '<div>' + myDevice.model.name;
      str += myDevice.imei ? ' (' + myDevice.imei + ')' : '';
      str += myDevice.location ? ' / ' + myDevice.location : '';
      if (myDevice.direction) {
        str += ' / ';
        str += myDevice.direction === 'IN' ?
          i18nService.get('label.in') : myDevice.direction === 'OUT' ?
            i18nService.get('label.out') : i18nService.get('label.inOut');
      }
      str += '</div>';
      return str;
    }

    function fullDate(dateParam) {
      return niceDate(dateParam, { fullDate: true });
    }

    function jobNumber(myJobNumber, escape = defaultEscape) {

      return escape(myJobNumber.code) + ' (' + escape(myJobNumber.text) + ')';
    }

    function jobNumberHtmlBlock(myJobNumber, escape) {
      return '<div>' + jobNumber(myJobNumber, escape) + '</div>';
    }

    function month(monthNumber) {
      return moment({ month: monthNumber - 1 }).format('MMMM');
    }

    function monthShort(monthNumber) {
      return moment({ month: monthNumber - 1 }).format('MMM');
    }

    function country(myCountry) {
      return myCountry.name || myCountry.id;
    }

    function niceDate(dateParam, args) {

      if (!dateParam) {
        return '';
      }

      var formats = {
        fi: {
          afterTwoDays: '[ylihuomenna]',
          nextDay: '[huomenna]',
          today: '[tänään]',
          twoDaysAgo: '[toissapäivänä]',
          lastDay: '[eilen]',
          lastWeek: '[viime] dddd[na]',
        },
        en: {
          afterTwoDays: '[day after tomorrow]',
          nextDay: '[tomorrow]',
          today: '[today]',
          twoDaysAgo: '[day before yesterday]',
          lastDay: '[yesterday]',
          lastWeek: '[last] dddd',
        },
      };

      var dateParts = getDateParts(dateParam);

      var year = dateParts.year;
      var month = dateParts.month;
      var day = dateParts.day;

      var dateMoment = moment([year, month - 1, day]);
      var now = moment().startOf('day');
      var pastDays = now.diff(dateMoment, 'day');

      var returnString = '';

      if (pastDays > 300 || (args && args.fullDate)) {
        if (args && args.weekday) {
          returnString += dateMoment.format('dd') + ' ';
        }
        returnString += day + '.' + month + '.' + year;
        return returnString;
      } else if (pastDays > 7 || (args && args.skipVerbal)) {
        if (args && args.weekday) {
          returnString += dateMoment.format('dd') + ' ';
        }
        returnString += day + '.' + month + '.';
        return returnString;
      } else if (pastDays > 2) {
        returnString = dateMoment.format(formats[moment.locale()].lastWeek);
        if (args && args.dateWithVerbal) {
          returnString += ' ' + day + '.' + month + '.';
        }
        return returnString;
      } else if (pastDays === 2) {
        returnString = dateMoment.format(formats[moment.locale()].twoDaysAgo);
        if (args && args.dateWithVerbal) {
          returnString += ' ' + day + '.' + month + '.';
        }
        return returnString;
      } else if (pastDays === 1) {
        returnString = dateMoment.format(formats[moment.locale()].lastDay);
        if (args && args.dateWithVerbal) {
          returnString += ' ' + day + '.' + month + '.';
        }
        return returnString;
      } else if (pastDays === 0) {
        returnString = dateMoment.format(formats[moment.locale()].today);
        if (args && args.dateWithVerbal) {
          returnString += ' ' + day + '.' + month + '.';
        }
        return returnString;
      } else if (pastDays === -1) {
        returnString = dateMoment.format(formats[moment.locale()].nextDay);
        if (args && args.dateWithVerbal) {
          returnString += ' ' + day + '.' + month + '.';
        }
        return returnString;
      } else if (pastDays === -2) {
        returnString = dateMoment.format(formats[moment.locale()].afterTwoDays);
        if (args && args.dateWithVerbal) {
          returnString += ' ' + day + '.' + month + '.';
        }
        return returnString;
      } else {
        return dateMoment.format('D.M.YYYY');
      }

    }

    function payment(myPayment) {
      var str = $filter('currency')(myPayment.amount) + ' / ' + myPayment.invoiceDate;
      if (myPayment.note) {
        str += ' (' + myPayment.note + ')';
      }
      return str;
    }

    function person(person, escape = defaultEscape) {

      if (!person || !hasName(person)) {
        return '';
      }

      if (person.fullNameAsString) {
        return escape(person.fullNameAsString);
      } else if (person.fullName && person.fullName.hasOwnProperty('lastName')) {
        var firstName = person.fullName.nickName || person.fullName.firstName;
        return firstName + ' ' + person.fullName.lastName;
      } else if (person.firstName && person.lastName) {
        return person.firstName + ' ' + person.lastName;
      } else {
        return person.name;
      }

    }

    function hasName(item) {
      if (!item) {
        return false;
      }
      return item.name ||
        item.fullName ||
        item.fullNameAsString ||
        item.firstName ||
        item.lastName;
    }

    function personReversed(myPerson, escape = defaultEscape) {

      if (!myPerson) {
        return '';
      }

      if (myPerson.fullNameAsString) {
        return escape(myPerson.fullNameAsString);
      } else if (myPerson.fullName && typeof myPerson.fullName === 'object' &&
        (myPerson.fullName.nickName || myPerson.fullName.firstName) &&
        myPerson.fullName.lastName) {
        var firstName = myPerson.fullName.nickName || myPerson.fullName.firstName;
        return escape(myPerson.fullName.lastName) + ', ' + escape(firstName);
      } else if (myPerson.lastName && myPerson.firstName) {
        return escape(myPerson.lastName) + ', ' + escape(myPerson.firstName);
      }

      return '';

    }

    function personReversedHtmlBlock(myPerson, escape) {
      return '<div>' + personReversed(myPerson, escape) + '</div>';
    }

    function profession(myProfession) {
      return myProfession.title;
    }

    function range(myRange, params) {

      const includeYear = !_.get(params, 'OMIT_YEAR');

      if (!myRange || (!myRange.startDate && !myRange.endDate)) {
        return '';
      } else if (!myRange.startDate) {
        return '– ' + myRange.endDate;
      } else if (!myRange.endDate) {
        return myRange.startDate + ' –';
      } else {
        return formattedRange();
      }

      function formattedRange () {
        var startParts = getDateParts(myRange.startDate);
        var endParts = getDateParts(myRange.endDate);

        if (myRange.startDate === myRange.endDate) {
          return endParts.day + '.' + endParts.month + '.' + (includeYear ? endParts.year : '');
        }

        if (startParts.year !== endParts.year) {
          return startParts.day + '.' + startParts.month + '.' + (includeYear ? startParts.year : '') + ' – ' +
            endParts.day + '.' + endParts.month + '.' + (includeYear ? endParts.year : '');
        } else if (startParts.month !== endParts.month) {
          return startParts.day + '.' + startParts.month + '. – ' +
            endParts.day + '.' + endParts.month + '.' + (includeYear ? endParts.year : '');
        } else {
          return startParts.day + '. – ' +
            endParts.day + '.' + endParts.month + '.' + (includeYear ? endParts.year : '');
        }
      }

    }

    function site(mySite, escape = defaultEscape) {
      if (!mySite) {
        return '';
      }
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix + escape(mySite.number) + ' ' + escape(mySite.name) + postfix;
    }

    function siteWithAddress(mySite, escape = defaultEscape) {

      if (!mySite) {
        return '';
      }
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix +
        escape(mySite.number) + ' ' + escape(mySite.name) +
        (prefix ? '<div class="site address">' : '(') +
        address(mySite) +
        (prefix ? '</div>' : ')') +
        postfix;

    }

    function region(Region, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix + escape(Region.name) + postfix;
    }

    function jobTitle(myJobTitle, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix + escape(myJobTitle.name) + postfix;
    }

    function material(material, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix + escape(material.name) + postfix;
    }

    function user(user, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      if (!user.lastName && !user.firstName && user.fullNameAsString) {
        return prefix + escape(user.fullNameAsString) + postfix;
      }

      return prefix + escape(user.lastName) + ', ' + escape(user.firstName) + postfix;
    }


    function userInfo(myUserInfo, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape? '</div>' : '';
      return prefix + escape(myUserInfo.lastName) + ', ' + escape(myUserInfo.firstName) + postfix;
    }

    function accessArea(myAccessArea, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix + escape(myAccessArea.fullName) + postfix;
    }

    function manpowerArea(manpowerArea, escape = defaultEscape) {
      var prefix = escape !== defaultEscape ? '<div>' : '';
      var postfix = escape !== defaultEscape ? '</div>' : '';

      return prefix + escape(manpowerArea.name) + postfix;
    }

  }

})();
